(() => {
	const script = document.currentScript;
	const scriptSrc = new URL(script.src);
	const locationId = script.getAttribute('data-lid') || scriptSrc.searchParams.get('lid');

	if (!locationId) {
		console.error(
			'Shopmonkey Chatbot: Location ID is required and should have been specified as a lid query parameter in the URL or as a lid data-attribute in the script tag.',
		);
		return;
	}

	const id = 'sm_chatbot';
	const idPrefix = `${id}_`;
	const target = script.getAttribute('data-target') || scriptSrc.searchParams.get('target');
	const manual = !!script.getAttribute('data-manual') || scriptSrc.searchParams.get('manual');
	const env = script.getAttribute('data-env') || scriptSrc.searchParams.get('env') || 'stable';
	const btnColor =
		script.getAttribute('data-btn-color') || scriptSrc.searchParams.get('btn-color') || '#1C3FFF';
	const iconColor =
		script.getAttribute('data-icon-color') ||
		scriptSrc.searchParams.get('icon-color') ||
		'#FFFFF';

	const iframe = document.createElement('iframe');
	iframe.id = `${idPrefix}iframe`;
	iframe.src = new URL(`/${locationId}?env=${env}`, scriptSrc.origin).toString();

	const style = document.createElement('style');
	style.id = `${idPrefix}style`;
	style.textContent = `
	#${idPrefix}iframe {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			width: 100%;
			height: 100vh;
			border-radius: 0;
			border: none;
			overflow: hidden;
			background: #FFF;
			z-index: 2147483647;
	}
	#${idPrefix}bubble {
		position: fixed;
		bottom: 20px;
		right: 20px;
		width: 60px;
		height: 60px;
		z-index: 2147483646;
	}
	@media (min-width: 640px) {
		#${idPrefix}iframe {
			top: auto;
			left: auto;
			bottom: 100px;
			right: 20px;
			width: 400px;
			height: 615px;
			min-height: 400px;
			max-height: calc(100vh - 120px);
			border-radius: 12px;
			box-shadow: 0px 3px 6px 0px rgba(29, 32, 48, 0.06), 0px 4px 8px 0px rgba(29, 32, 48, 0.12);
		}
	}`;
	document.body.appendChild(style);

	let bubble = target ? document.querySelector(target) : null;
	const customBubble = !!bubble || manual;
	if (!customBubble) {
		bubble = document.createElement('div');
		bubble.id = `${idPrefix}bubble`;
		bubble.innerHTML = `
			<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect x="2" y="2" width="56" height="56" rx="28" fill="${btnColor}" stroke="${iconColor}" stroke-width="2"/>
				<g id="${idPrefix}open" style="display: block;">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M18 23.6542C18 21.3599 19.8468 19.5 22.125 19.5H32.625C34.9032 19.5 36.75 21.3599 36.75 23.6542V30.2108C36.75 32.5299 34.8783 34.3147 32.625 34.3147L27.4366 34.3147L23.1582 36.8999C22.2084 37.4738 21 36.7848 21 35.6693V34.1616C19.2813 33.6826 18 32.1296 18 30.2108V23.6542ZM22.125 21.7659C21.0895 21.7659 20.25 22.6113 20.25 23.6542V30.2108C20.25 31.229 21.0646 32.0487 22.125 32.0487C22.4234 32.0487 22.7095 32.1681 22.9205 32.3806C23.1315 32.5931 23.25 32.8812 23.25 33.1817V34.2019L26.5462 32.2102C26.721 32.1045 26.9211 32.0487 27.125 32.0487L32.625 32.0487C33.6854 32.0487 34.5 31.229 34.5 30.2108V23.6542C34.5 22.6113 33.6605 21.7659 32.625 21.7659H22.125Z" fill="#FFF"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M38.4597 27.8568H39.75C41.4759 27.8568 41.9932 29.2658 41.9932 31.0039V36.375C41.9932 37.7137 41.1632 38.8572 39.9932 39.312L39.9933 40.7431C39.9933 41.7177 38.9376 42.321 38.1078 41.8196C38.0696 41.7965 38.0329 41.7712 37.9977 41.7438L35.1502 39.5225L31.0351 39.5221C30.2175 39.5221 29.4223 39.1424 28.9099 38.53C28.7315 38.3167 28.5804 38.0668 28.4781 37.7846C28.2652 37.1968 28.2832 36 28.2832 36H30.5918C30.5918 36 30.6061 37.0406 30.6306 37.0699C30.7179 37.1742 30.8847 37.2562 31.0351 37.2562L35.5351 37.2566C35.7845 37.2566 36.0268 37.3401 36.2239 37.4939L37.7432 38.679L37.7432 38.3892C37.7432 37.7635 38.2468 37.2562 38.8682 37.2562C39.3514 37.2562 39.7432 36.8617 39.7432 36.375V31.0039C39.75 30.8402 39.6594 30.1227 38.4597 30.1227V27.8568Z" fill="#FFF"/>
				</g>
				<g id="${idPrefix}close" style="display: none;">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M21.3766 21.3766C21.8787 20.8745 22.6928 20.8745 23.1949 21.3766L30 28.1817L36.8051 21.3766C37.3073 20.8745 38.1213 20.8745 38.6234 21.3766C39.1255 21.8787 39.1255 22.6927 38.6234 23.1948L31.8183 30L38.6234 36.8051C39.1255 37.3073 39.1255 38.1213 38.6234 38.6234C38.1213 39.1255 37.3072 39.1255 36.8051 38.6234L30 31.8183L23.1948 38.6234C22.6927 39.1255 21.8787 39.1255 21.3766 38.6234C20.8745 38.1213 20.8745 37.3073 21.3766 36.8051L28.1817 30L21.3766 23.1949C20.8745 22.6927 20.8745 21.8787 21.3766 21.3766Z" fill="#FFF"/>
				</g>
				<style type="text/css">
					rect:hover { filter: brightness(80%); cursor: pointer; }
					path { pointer-events: none; }
				</style>
			</svg>
		`;
		document.body.appendChild(bubble);
	}

	window.shopmonkeyChatBot = {
		addEventListener: function (event, callback) {
			this.callbacks = this.callbacks || {};
			this.callbacks[event] = [...(this.callbacks[event] || []), callback];
		},
		removeEventListener: function (event, callback) {
			const callbacks = this.callbacks?.[event];
			if (callbacks) {
				this.callbacks[event] = callbacks.filter((c) => c !== callback);
			}
		},
		trigger: function (event, data) {
			const callbacks = this.callbacks?.[event];
			if (callbacks) {
				const _data = { ...data, type: event, sessionid: this.sessionid };
				for (const callback of callbacks) {
					callback(_data);
				}
			}
			const wcallbacks = this.callbacks?.['*'];
			if (wcallbacks) {
				const _data = { ...data, type: event, sessionid: this.sessionid };
				for (const callback of wcallbacks) {
					callback(_data);
				}
			}
			if (iframe.contentWindow && typeof event === 'string') {
				iframe.contentWindow.postMessage({ event, data, source: 'chatbot_script' }, '*');
			}
		},
		setSessionId: function (id) {
			this.sessionid = id;
			this.trigger('ready');
		},
		sendEvent: function (data) {
			switch (data?.type) {
				case 'init': {
					this.setSessionId(data.sessionid);
					break;
				}
				case 'show': {
					if (!document.body.contains(iframe)) {
						document.body.appendChild(iframe);
					}
					if (!customBubble) {
						document.getElementById(`${idPrefix}open`).style.display = 'none';
						document.getElementById(`${idPrefix}close`).style.display = 'block';
					}
					this.trigger('show');
					break;
				}
				case 'hide': {
					if (document.body.contains(iframe)) {
						document.body.removeChild(iframe);
						if (!customBubble) {
							document.getElementById(`${idPrefix}open`).style.display = 'block';
							document.getElementById(`${idPrefix}close`).style.display = 'none';
						}
					}
					this.trigger('hide');
					break;
				}
				case 'toogle': {
					if (document.body.contains(iframe)) {
						document.body.removeChild(iframe);
						if (!customBubble) {
							document.getElementById(`${idPrefix}open`).style.display = 'block';
							document.getElementById(`${idPrefix}close`).style.display = 'none';
						}
						this.trigger('hide');
					} else {
						document.body.appendChild(iframe);
						if (!customBubble) {
							document.getElementById(`${idPrefix}open`).style.display = 'none';
							document.getElementById(`${idPrefix}close`).style.display = 'block';
						}
						this.trigger('show');
					}
					break;
				}
			}
		},
	};

	if (bubble) {
		bubble.onclick = () => window.shopmonkeyChatBot.sendEvent({ type: 'toogle' });
	}

	// relay message
	window.addEventListener('message', (event) => {
		if (event.origin === scriptSrc.origin && event?.data?.source === 'chatbot_frame') {
			const { type, ...data } = event.data;
			window.shopmonkeyChatBot.sendEvent({ type, ...data });
		}
	});
})();
